body,
body * {
  font-family: "Open Sans", sans-serif;
}
.overlay-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
}
.eligibility-header-outer {
  background: radial-gradient(
      circle at 57% 36%,
      hsla(263, 0%, 78%, 0.04) 0%,
      hsla(263, 0%, 78%, 0.04) 10%,
      transparent 10%,
      transparent 100%
    ),
    radial-gradient(
      circle at 22% 61%,
      hsla(263, 0%, 78%, 0.04) 0%,
      hsla(263, 0%, 78%, 0.04) 36%,
      transparent 36%,
      transparent 100%
    ),
    radial-gradient(
      circle at 68% 97%,
      hsla(263, 0%, 78%, 0.04) 0%,
      hsla(263, 0%, 78%, 0.04) 41%,
      transparent 41%,
      transparent 100%
    ),
    radial-gradient(
      circle at 57% 89%,
      hsla(263, 0%, 78%, 0.04) 0%,
      hsla(263, 0%, 78%, 0.04) 30%,
      transparent 30%,
      transparent 100%
    ),
    radial-gradient(
      circle at 39% 80%,
      hsla(263, 0%, 78%, 0.04) 0%,
      hsla(263, 0%, 78%, 0.04) 22%,
      transparent 22%,
      transparent 100%
    ),
    radial-gradient(
      circle at 88% 71%,
      hsla(263, 0%, 78%, 0.04) 0%,
      hsla(263, 0%, 78%, 0.04) 30%,
      transparent 30%,
      transparent 100%
    ),
    linear-gradient(0deg, rgb(255, 255, 255), rgb(255, 255, 255)) !important;
  box-shadow: 0 7px 6px -6px #e4e2e2;
}
.eligibility-header-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1360px;
  margin: 0px auto;
  padding: 10px 20px;
}
.admin-eligibility-table {
  max-width: 1360px;
  margin: 0px auto;
  padding: 10px 20px;
}
.eligibility-header-inner span {
  display: flex;
  align-items: center;
}
.eligibility-header-inner span > a {
  margin-left: 10px;
  color: #fff;
  font-weight: 500;
  display: flex;
  padding: 6px 25px;
  font-size: 15px;
  background-color: #dc3545;
  border-radius: 10px;
}
.tableWithCSV {
  font-size: 16px;
}
.admin-eligibility-table .tableWithCSV .divTableHeading {
  background-color: #dc3b45;
}
.admin-eligibility-table .tableWithCSV .divTableCell,
.admin-eligibility-table .divTableHead {
  border: none;
}
.admin-eligibility-table .tableWithCSV .csvFileDownloader {
  text-align: right;
}
.eligibility-menu ul {
  list-style: none;
  display: flex;
  margin-bottom: 0px;
  align-items: center;
}
.eligibility-menu {
  background: #f1f1f1;
  padding: 10px 0px;
}
.eligibility-menu ul > li {
  margin-right: 15px;
}
.eligibility-menu ul > li a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  max-width: 260px;
  padding: 5px 25px;
  background-color: #dc3b45;
  color: #fff;
  width: 100%;
}
div.group {
  padding: 20px 0px;
  border-bottom: 2px solid #f9f9f9;
  border-top: 2px solid #f9f9f9;
}
div.group > .form-group {
  flex: 1 1 0;
  margin: 0px 10px;
}
.eligible-check-form h4 {
  color: #dc3b45;
  font-size: 20px;
  position: relative;
  font-weight: 600;
  padding: 10px 0px;
  margin: 0;
}
.eligible-check-form h4 > i {
  margin-right: 15px;
}
@media (max-width: 991px) {
  .eligibility-header-inner {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
  }
  .eligibility-header-inner span {
    margin-bottom: 10px;
  }
  .eligibility-header-inner span > a {
    margin-left: 0px;
  }
}
@media (max-width: 767px) {
  div.group,
  .eligibility-header-inner {
    flex-wrap: wrap;
    justify-content: center;
  }
  div.group > .form-group {
    flex: 100%;
    margin: 0px 0px 10px 0px;
  }
}
